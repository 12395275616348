export default {
  namespaced: true,
  state: {
    details: null,
  },
  mutations: {
    setDetails(state, details) {
      state.details = details;
    },
    clearDetails(state) {
      state.details = null;
    },
  },
};
