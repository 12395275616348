import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
  // themeName: "Metronic",
  // themeVersion: "8.0.22",
  themeName: "IBN WealthPro",
  themeVersion: "0.0.1",
  demo: "demo1",
  main: {
    type: "default",
    primaryColor: "#C59408",
    logo: {
      dark: "/media/logos/logo-1-dark.png",
      light: "/media/logos/logo.png"
    }
  },
  loader: {
    logo: "media/logos/logo.png",
    display: true,
    type: "spinner-logo"
  },
  scrollTop: {
    display: true
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  aside: {
    display: true,
    theme: "light",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true
  },
  content: {
    width: "fixed"
  },
  footer: {
    width: "fluid"
  }
};

export default config;
