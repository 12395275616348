const ID_TOKEN_KEY = "id_token" as string;
const USER_ROLE = "userRole" as string;
const USER_AVATAR = "userAvatar" as string;
const THIRD_PARTY_PM = "thirdPartyPM" as string;
const THIRD_PARTY_CM = "thirdPartyCM" as string;

/**
 * @description get token from localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  // window.localStorage.removeItem(ID_TOKEN_KEY);
  window.localStorage.clear();
};

/**
 * @description get user detail from localStorage
 */
export const getUserRole = (): string | null => {
  return window.localStorage.getItem(USER_ROLE);
};

/**
 * @description save user detail into localStorage
 * @param userRole: string
 */
export const saveUserRole = (userRole: string): void => {
  window.localStorage.setItem(USER_ROLE, userRole);
};

/**
 * @description save user avatar into localStorage
 * @param userAvatar: string
 */
export const saveUserAvatar = (userAvatar: string): void => {
  window.localStorage.setItem(USER_AVATAR, userAvatar);
};

/**
 * @description save motor third-party private package public ID into localStorage
 * @param thirdPartyPM: string
 */
export const thirdPartyPM = (thirdPartyPM: string): void => {
  window.localStorage.setItem(THIRD_PARTY_PM, thirdPartyPM);
};

/**
 * @description save motor third-party commercial package public ID into localStorage
 * @param thirdPartyCM: string
 */
export const thirdPartyCM = (thirdPartyCM: string): void => {
  window.localStorage.setItem(THIRD_PARTY_CM, thirdPartyCM);
};

/**
 * @description get motor third-party private package public ID from localStorage
 *
 */
export const getThirdPartyPM = (): string | null => {
  return window.localStorage.getItem(THIRD_PARTY_PM);
};

/**
 * @description get motor third-party commercial package public ID from localStorage
 *
 */
export const getThirdPartyCM = (): string | null => {
  return window.localStorage.getItem(THIRD_PARTY_CM);
};


export default {
  getToken,
  saveToken,
  destroyToken,
  getUserRole,
  saveUserRole,
  saveUserAvatar,
  thirdPartyPM,
  thirdPartyCM,
  getThirdPartyPM,
  getThirdPartyCM,
};
