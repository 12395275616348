enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  CART_ACTION = "cartAction",
  CART_ITEMS_ACTION = "cartItemsAction",
  ADD_TO_CART_ACTION = "addToCartAction",
  DELETE_FROM_CART_ACTION = "deleteFromCartAction",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ROLE = "setRole",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  CART_MUTATION = "cartMutation",
  CART_ITEMS_MUTATION = "cartItemsMutation",
  ADD_TO_CART_MUTATION = "addToCartMutation",
  DELETE_FROM_CART_MUTATION = "deleteFromCartMutation",
  CART_TOTAL_AMOUNT_MUTATION = "cartTotalAmountMutation",
  NOTIFICATION_MUTATION = "notificationCount",
  SET_AVATAR = "avatars"
}

export { Actions, Mutations };
