import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import variables from "@/router/api";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isUserAuthenticated) {
    next();
    return;
  }
  next({ name: "dashboard" });
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isUserAuthenticated) {
    next();
    return;
  }
  // next({ name: "landing-page" });
  next({ name: "sign-in" });
};

const ifNotAdministrator = (to, from, next) => {
  if (
    store.getters.currentUser.role != variables.ADMIN_USER_ROLE ||
    store.getters.currentUser.role != variables.SUPER_ADMIN_USER_ROLE
  ) {
    next();
    return;
  }
  next({ name: "404" });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/finance/dashboard",
        name: "finance-dashboard",
        component: () => import("@/views/finance/Dashboard.vue"),
      },
      {
        path: "/finance/clients",
        name: "finance-clients",
        component: () => import("@/views/finance/users/clients/Index.vue"),
      },
      {
        path: "/finance/clients/details/:publicId",
        name: "finance-client-details",
        component: () => import("@/views/finance/users/clients/Details.vue"),
        props: true,
      },
      {
        path: "/finance/clients/policy-information/motor-insurance/:publicId",
        name: "finance-client-policy-information",
        component: () =>
          import(
            "@/views/finance/credit-notes/policy-information/MotorInsurance.vue"
          ),
        props: true,
      },
      {
        path: "/finance/underwriters",
        name: "finance-underwriters",
        component: () => import("@/views/finance/users/underwriters/Index.vue"),
      },
      {
        path: "/finance/underwriters/details/:publicId",
        name: "finance-undewriter-details",
        component: () =>
          import("@/views/finance/users/underwriters/Details.vue"),
        props: true,
      },
      {
        path: "/finance/credit-notes",
        name: "finance-credit-notes",
        component: () => import("@/views/finance/credit-notes/CreditNotes.vue"),
      },
      {
        path:
          "/finance/credit-notes/policy-information/motor-insurance/:publicId",
        name: "finance-credit-note-motor-insurance-policy-information",
        component: () =>
          import(
            "@/views/finance/credit-notes/policy-information/MotorInsurance.vue"
          ),
        props: true,
      },
      {
        path: "/finance/credit-notes/settle-credit-note/:publicId",
        name: "finance-settle-credit-note",
        component: () =>
          import("@/views/finance/credit-notes/SettleCreditNote.vue"),
        props: true,
      },
      {
        path: "/finance/debit-notes",
        name: "finance-debit-notes",
        component: () => import("@/views/finance/DebitNotes.vue"),
      },
      {
        path: "/finance/payments",
        name: "finance-payments",
        component: () => import("@/views/finance/payments/Index.vue"),
      },
      {
        path: "/finance/payments/details/:publicId",
        name: "finance-payment-details",
        component: () => import("@/views/finance/payments/Details.vue"),
        props: true,
      },
      {
        path: "/underwriter/dashboard",
        name: "underwriter-dashboard",
        component: () => import("@/views/underwriter/Dashboard.vue"),
      },
      {
        path: "/underwriter/clients",
        name: "underwriter-clients",
        component: () => import("@/views/underwriter/clients/Clients.vue"),
      },
      {
        path: "/underwriter/clients/details/:publicId",
        name: "underwriter-client-details",
        component: () => import("@/views/underwriter/clients/Details.vue"),
        props: true,
      },
      {
        path: "/underwriter/clients/claims",
        name: "underwriter-client-claims-listing",
        component: () => import("@/views/underwriter/clients/claims/Index.vue"),
      },
      {
        path: "/underwriter/claims/claim-information/motor-insurance/:publicId",
        name: "underwriter-motor-insurance-claims-details",
        component: () =>
          import(
            "@/views/underwriter/clients/claims/motor-insurance/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/claims/claim-information/house-insurance/building/:publicId",
        name: "underwriter-building-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/underwriter/clients/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/claims/claim-information/house-insurance/personal-accident/:publicId",
        name: "underwriter-personal-accident-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/personal-accident/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/claims/claim-information/house-insurance/contents/:publicId",
        name: "underwriter-contents-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/claims/house-insurance/building/settlement-offer/:publicId",
        name: "underwriter-building-house-insurance-claims-settlement-offer",
        component: () =>
          import(
            "@/views/underwriter/clients/claims/house-insurance/building/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path: "/underwriter/claims/motor-insurance/settlement-offer/:publicId",
        name: "underwriter-motor-insurance-claims-settlement-offer",
        component: () =>
          import(
            "@/views/underwriter/clients/claims/motor-insurance/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path: "/underwriter/clients/claims/settled",
        name: "underwriter-client-claims-settled",
        component: () =>
          import("@/views/underwriter/clients/claims/Settled.vue"),
      },
      {
        path: "/underwriter/clients/claims/outstanding",
        name: "underwriter-client-claims-outstanding",
        component: () =>
          import("@/views/underwriter/clients/claims/Outstanding.vue"),
      },
      {
        path: "/underwriter/clients/claims/settlement-offers",
        name: "underwriter-client-claims-settlement-offers",
        component: () =>
          import("@/views/underwriter/clients/claims/SettlementOffers.vue"),
      },
      {
        path: "/underwriter/clients/credit-notes",
        name: "underwriter-client-credit-notes",
        component: () =>
          import("@/views/underwriter/clients/credit-notes/Index.vue"),
      },
      {
        path: "/underwriter/clients/credit-notes/details/:publicId",
        name: "underwriter-credit-note-details",
        component: () =>
          import("@/views/underwriter/clients/credit-notes/Details.vue"),
        props: true,
      },
      {
        path:
          "/underwriter/clients/policy-information/motor-insurance/:publicId",
        name: "underwriter-motor-insurance-policy-information",
        component: () =>
          import(
            "@/views/underwriter/policies/policy-information/MotorInsurance.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/clients/policy-information/motor-insurance/motor-schedule-details/:publicId",
        name: "underwriter-motor-insurance-policy-details",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/motor-insurance/PolicyDetails.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwrter/clients/policy/policy-information/house-insurance/building/:publicId",
        name: "underwriter-building-house-policy-information",
        component: () =>
          import(
            "@/views/underwriter/policies/policy-information/BuildingPolicyInformation.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/clients/policy-information/house-insurance/owned-building/:publicId",
        name: "underwriter-house-insurance-owned-building-policy-information",
        component: () =>
          import(
            "@/views/underwriter/policies/policy-information/OwnedBuilding.vue"
          ),
        props: true,
      },
      {
        path:
          "/underwriter/clients/policy-information/house-insurance/rented-building/:publicId",
        name: "underwriter-house-insurance-rented-building-policy-information",
        component: () =>
          import(
            "@/views/underwriter/policies/policy-information/RentedBuilding.vue"
          ),
        props: true,
      },
      {
        path: "/underwriter/policies",
        name: "underwriter-policies",
        component: () => import("@/views/underwriter/policies/Index.vue"),
      },
      {
        path: "/underwriter/policies/upload-document",
        name: "underwriter-upload-policy-document",
        component: () =>
          import("@/views/underwriter/policies/PolicyDocumentation.vue"),
      },
      {
        path: "/underwriter/policies/upload-certificate",
        name: "underwriter-upload-policy-certificate",
        component: () =>
          import("@/views/underwriter/policies/UploadCertificate.vue"),
      },
      {
        path: "/underwriter/policies/terms-and-conditions",
        name: "underwriter-policy-terms-and-conditions",
        component: () =>
          import("@/views/underwriter/policies/TermsAndConditions.vue"),
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/reusable/Dashboard.vue"),
      },
      {
        path: "/claims/claims-history",
        name: "client-claims-history",
        component: () => import("@/views/reusable/claims/ClaimsHistory.vue"),
      },
      {
        path: "/claims/report-claims",
        name: "client-report-claims",
        component: () => import("@/views/reusable/claims/ReportClaims.vue"),
      },
      {
        path: "/claims/claim-information/motor-insurance/:publicId",
        name: "motor-insurance-claims-details",
        component: () =>
          import("@/views/reusable/claims/motor-insurance/Details.vue"),
        props: true,
      },
      {
        path:
          "/claims/claim-information/house-insurance/owned-building/:publicId",
        name: "owned-building-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/claims/claim-information/house-insurance/rented-building/:publicId",
        name: "rented-building-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/claims/claim-information/house-insurance/personal-accident/:publicId",
        name: "personal-accident-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/personal-accident/Details.vue"
          ),
        props: true,
      },
      {
        path: "/claims/house-insurance/personal-accident/edit/:publicId",
        name: "edit-personal-accident-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/personal-accident/Edit.vue"
          ),
        props: true,
      },
      {
        path: "/claims/claim-information/house-insurance/contents/:publicId",
        name: "contents-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path: "/claims/house-insurance/contents/edit/:publicId",
        name: "edit-contents-house-insurance-claims-details",
        component: () =>
          import("@/views/reusable/claims/house-insurance/contents/Edit.vue"),
        props: true,
      },
      {
        path: "/claims/house-insurance/building/edit/:publicId",
        name: "edit-building-house-insurance-claims",
        component: () =>
          import("@/views/reusable/claims/house-insurance/building/Edit.vue"),
        props: true,
      },
      {
        path: "/claims/motor-insurance/edit/:publicId",
        name: "motor-insurance-claims-edit",
        component: () =>
          import("@/views/reusable/claims/motor-insurance/Edit.vue"),
        props: true,
      },
      {
        path: "/claims/motor-insurance/settlement-offer/:publicId",
        name: "motor-insurance-claims-settlement-offer",
        component: () =>
          import("@/views/reusable/claims/motor-insurance/SettlementOffer.vue"),
        props: true,
      },
      {
        path: "/claims/house-insurance/building/settlement-offer/:publicId",
        name: "building-house-insurance-claims-settlement-offer",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path: "/claims/motor-insurance/discharge-voucher/:publicId",
        name: "motor-insurance-claims-discharge-voucher",
        component: () =>
          import(
            "@/views/reusable/claims/motor-insurance/DischargeVoucher.vue"
          ),
        props: true,
      },
      {
        path: "/claims/house-insurance/building/discharge-voucher/:publicId",
        name: "building-house-insurance-claims-discharge-voucher",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/DischargeVoucher.vue"
          ),
        props: true,
      },
      {
        path: "/admin/products/main-category",
        name: "products-main-category",
        component: () => import("@/views/admin/products/MainCategory.vue"),
      },
      {
        path: "/admin/products/add-sub-category",
        name: "products-add-sub-category",
        component: () => import("@/views/admin/products/AddSubCategory.vue"),
      },
      {
        path: "/admin/products/sub-category",
        name: "products-sub-category",
        component: () => import("@/views/admin/products/SubCategory.vue"),
      },
      {
        path: "/admin/policies/all-policies",
        name: "all-policies-list",
        component: () => import("@/views/admin/policies/Policies.vue"),
      },
      {
        path: "/admin/policy-migration",
        name: "admin-policy-migration-list",
        component: () => import("@/views/admin/policy-migration/Index.vue"),
      },
      {
        path: "/admin/policy-migration/uploaded-schedules",
        name: "admin-policy-migration-uploaded-schedules-list",
        component: () =>
          import("@/views/admin/policy-migration/UploadedSchedule.vue"),
      },
      {
        path: "/admin/policy-migration/rate",
        name: "admin-policy-migration-rate",
        component: () => import("@/views/admin/policy-migration/Rate.vue"),
      },
      {
        path: "/admin/policy-migration/create",
        name: "admin-policy-migration-creation",
        component: () => import("@/views/admin/policy-migration/Migration.vue"),
      },
      {
        path: "/admin/policy-migration/dependencies",
        name: "admin-policy-migration-dependencies",
        component: () =>
          import("@/views/admin/policy-migration/Dependencies.vue"),
      },
      {
        path: "/admin/policy-migration/motor-insurance",
        name: "admin-policy-migration-motor-creation",
        component: () =>
          import("@/views/admin/policy-migration/MotorInsurance.vue"),
      },
      {
        path: "/admin/policy-migration/motor-insurance/edit/:publicId",
        name: "admin-policy-migration-motor-insurance-edit",
        component: () =>
          import(
            "@/views/admin/policy-migration/edit/motor-insurance/Index.vue"
          ),
      },
      {
        path: "/admin/policy-migration/house-insurance/edit/:publicId",
        name: "admin-policy-migration-house-insurance-edit",
        component: () =>
          import(
            "@/views/admin/policy-migration/edit/house-insurance/Index.vue"
          ),
      },
      {
        path: "/admin/policy-migration/house-insurance",
        name: "admin-policy-migration-house-creation",
        component: () =>
          import("@/views/admin/policy-migration/HouseInsurance.vue"),
      },
      {
        path: "/admin/credit-notes",
        name: "all-credit-notes",
        component: () => import("@/views/admin/finance/CreditNotes.vue"),
      },
      {
        path: "/admin/credit-notes/:publicId",
        name: "admin-credit-note-details",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/AdminCreditNote.vue"
          ),
        props: true,
      },
      {
        path: "/admin/debit-notes/:publicId",
        name: "admin-debit-note-details",
        component: () =>
          import("@/views/reusable/policies/policy-information/Invoice.vue"),
        props: true,
      },
      {
        path: "/admin/debit-notes",
        name: "all-debit-notes",
        component: () => import("@/views/admin/finance/DebitNotes.vue"),
      },
      {
        path: "/admin/payments",
        name: "all-payments",
        component: () => import("@/views/admin/finance/Payments.vue"),
      },
      {
        path: "/admin/policy/policy-information/motor-insurance/:publicId",
        name: "admin-motor-policy-details",
        component: () => import("@/views/admin/policies/Details.vue"),
        props: true,
      },
      {
        path: "/admin/policy/policy-information/motor-insurance/edit/:publicId",
        name: "admin-edit-motor-policy-details",
        component: () => import("@/views/admin/policies/Details.vue"),
        props: true,
      },
      {
        path: "/admin/policies/upload-document",
        name: "admin-upload-policy-document",
        component: () => import("@/views/admin/policies/UploadDocument.vue"),
      },
      {
        path: "/admin/policies/upload-certificate",
        name: "admin-upload-policy-certificate",
        component: () => import("@/views/admin/policies/UploadCertificate.vue"),
      },
      {
        path: "/admin/policies/approve-certificate",
        name: "admin-approve-policy-certificate",
        component: () => import("@/views/admin/policies/UploadCertificate.vue"),
      },
      {
        path: "/admin/cooperative/umbrella-policy",
        name: "admin-umbrella-policy",
        component: () =>
          import("@/views/admin/policies/umbrella-policy/Index.vue"),
      },
      {
        path: "/admin/cooperative/umbrella-policy/create",
        name: "create-admin-umbrella-policy",
        component: () =>
          import(
            "@/views/admin/policies/umbrella-policy/CreateUmbrellaPolicy.vue"
          ),
      },
      {
        path: "/admin/cooperative/umbrella-policy/edit/:publicId",
        name: "edit-admin-umbrella-policy",
        component: () =>
          import(
            "@/views/admin/policies/umbrella-policy/EditUmbrellaPolicy.vue"
          ),
        props: true,
      },
      {
        path: "/activity-log",
        name: "all-activity-logs",
        component: () => import("@/views/admin/ActivityLog.vue"),
      },
      {
        path: "/admin/bank",
        name: "admin-bank",
        component: () => import("@/views/admin/Banks.vue"),
      },
      {
        path: "/admin/business-industries",
        name: "admin-business-industries",
        component: () => import("@/views/admin/BusinessIndustries.vue"),
      },
      {
        path: "/admin/department",
        name: "admin-department",
        component: () => import("@/views/admin/Department.vue"),
      },
      {
        path: "/admin/designations",
        name: "admin-designations",
        component: () => import("@/views/admin/Designations.vue"),
      },
      {
        path: "/admin/revenue-center",
        name: "admin-revenue-center",
        component: () => import("@/views/admin/RevenueCenter.vue"),
      },
      {
        path: "/authorization/permissions",
        name: "authorization-permissions",
        component: () => import("@/views/admin/authorization/Permissions.vue"),
      },
      {
        path: "/authorization/roles",
        name: "authorization-roles",
        component: () => import("@/views/admin/authorization/Roles.vue"),
      },
      {
        path: "/admin/users/cooperative-societies/create",
        name: "admin-create-cooperative-societies",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Create.vue"),
      },
      {
        path: "/admin/users/cooperative-societies/edit/:publicId",
        name: "admin-edit-cooperative-societies",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Edit.vue"),
        props: true,
      },
      {
        path: "/admin/users/cooperative-societies/:publicId",
        name: "admin-cooperative-societies-details",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Details.vue"),
        props: true,
      },
      {
        path: "/admin/users/cooperative-societies",
        name: "admin-cooperative-societies",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Index.vue"),
      },
      {
        path: "/admin/rates",
        name: "admin-rates",
        component: () => import("@/views/admin/rates/Index.vue"),
      },
      {
        path: "/admin/policies/approve-certificate",
        name: "admin-approve-policy-certificate",
        component: () =>
          import("@/views/admin/policies/ApproveCertificate.vue"),
      },
      {
        path: "/admin/policies/approve-document",
        name: "admin-approve-policy-document",
        component: () => import("@/views/admin/policies/ApproveDocument.vue"),
      },
      {
        path: "/admin/users/underwriters",
        name: "admin-underwriters-users",
        component: () => import("@/views/admin/users/underwriters/Index.vue"),
      },
      {
        path: "/admin/users/underwriters/:publicId",
        name: "admin-underwriters-users-details",
        component: () => import("@/views/admin/users/underwriters/Details.vue"),
        props: true,
      },
      {
        path: "/admin/users/underwriters/create",
        name: "admin-create-underwriter-user",
        // component: () => import("@/views/admin/users/underwriters/Create.vue"),
        component: () =>
          import("@/views/reusable/underwriters/AddUnderwriters.vue"),
      },
      {
        path: "/admin/users/underwriters/edit/:publicId",
        name: "admin-edit-underwriters-user",
        component: () => import("@/views/admin/users/underwriters/Edit.vue"),
        props: true,
      },
      {
        path: "/admin/users/underwriters/package/create",
        name: "admin-create-underwriters-package",
        component: () =>
          import("@/views/admin/users/underwriters/packages/Create.vue"),
      },
      {
        path: "/admin/users/underwriters/package/edit/:publicId/:underwriterId",
        name: "admin-edit-underwriters-package",
        component: () =>
          import("@/views/admin/users/underwriters/packages/Edit.vue"),
        props: true,
      },
      {
        path: "/admin/users/staffs",
        name: "admin-staff-users",
        component: () => import("@/views/admin/users/staffs/Index.vue"),
      },
      {
        path: "/admin/users/staffs/details/:publicId",
        name: "admin-staff-user-details",
        component: () => import("@/views/admin/users/staffs/Details.vue"),
        props: true,
      },
      {
        path: "/admin/users/staffs/edit/:publicId",
        name: "admin-edit-staff-user-details",
        component: () => import("@/views/admin/users/staffs/Edit.vue"),
        props: true,
      },
      {
        path: "/admin/users/staffs/create",
        name: "admin-create-staff-user",
        component: () => import("@/views/admin/users/staffs/Create.vue"),
      },
      {
        path: "admin/users/administrators",
        name: "admin-administrator-users",
        component: () => import("@/views/admin/users/administrators/Index.vue"),
      },
      {
        path: "/admin/users/administrators/details/:publicId",
        name: "administrators-user-details",
        component: () =>
          import("@/views/admin/users/administrators/Details.vue"),
        props: true,
      },
      {
        path: "/admin/users/administrators/edit/:publicId",
        name: "edit-administrators-user-details",
        component: () => import("@/views/admin/users/administrators/Edit.vue"),
        props: true,
      },
      {
        path: "admin/users/administrators/create",
        name: "admin-create-administrator-user",
        component: () =>
          import("@/views/admin/users/administrators/Create.vue"),
      },
      {
        path: "/users/edit-cooperative-society",
        name: "users-edit-cooperative-society",
        component: () =>
          import("@/views/admin/users/EditCooperativeSociety.vue"),
      },
      {
        path: "/users/adjusters",
        name: "users-adjusters",
        component: () => import("@/views/admin/Adjusters.vue"),
      },
      {
        path: "admin/users/individual-clients/create",
        name: "admin-create-individual-client",
        component: () =>
          import("@/views/admin/users/individual-clients/Create.vue"),
      },
      {
        path: "/admin/users/individual-clients",
        name: "admin-users-individual-clients",
        component: () =>
          import("@/views/admin/users/individual-clients/Index.vue"),
      },
      {
        path: "/admin/users/individual-clients/with-policies",
        name: "admin-users-individual-clients-with-policies",
        component: () =>
          import("@/views/admin/users/individual-clients/WithPolicies.vue"),
      },
      {
        path: "/admin/users/details/:publicId",
        name: "admin-users-individual-clients-details",
        component: () =>
          import("@/views/admin/users/individual-clients/Details.vue"),
        props: true,
      },
      {
        path: "/admin/claims",
        name: "admin-client-claims-listing",
        component: () => import("@/views/admin/claims/Index.vue"),
      },
      {
        path: "/admin/claims/settlement-offers",
        name: "admin-client-claims-settlement-offers-listing",
        component: () => import("@/views/admin/claims/SettlementOffers.vue"),
      },
      {
        path: "/admin/claims/claim-information/motor-insurance/:publicId",
        name: "admin-client-motor-insurance-claims-details",
        component: () =>
          import("@/views/admin/claims/motor-insurance/Details.vue"),
        props: true,
      },
      {
        path: "/admin/claims/motor-insurance/edit/:publicId",
        name: "admin-motor-insurance-claims-edit",
        component: () =>
          import("@/views/admin/claims/motor-insurance/Edit.vue"),
        props: true,
      },
      {
        path: "/admin/claims/motor-insurance/settlement-offer/:publicId",
        name: "admin-motor-insurance-claims-settlement-offer",
        component: () =>
          import("@/views/admin/claims/motor-insurance/SettlementOffer.vue"),
        props: true,
      },
      {
        path: "/admin/claims/motor-insurance/discharge-voucher/:publicId",
        name: "admin-motor-insurance-claims-discharge-voucher",
        component: () =>
          import("@/views/admin/claims/motor-insurance/DischargeVoucher.vue"),
        props: true,
      },
      {
        path:
          "/admin/claims/house-insurance/building/discharge-voucher/:publicId",
        name: "admin-building-house-insurance-claims-discharge-voucher",
        component: () =>
          import(
            "@/views/admin/claims/house-insurance/building/DischargeVoucher.vue"
          ),
        props: true,
      },
      {
        path:
          "/admin/claims/claim-information/house-insurance/building/:publicId",
        name: "admin-building-house-insurance-claims-details",
        component: () =>
          import("@/views/admin/claims/house-insurance/building/Details.vue"),
        props: true,
      },
      {
        path: "/admin/claims/house-insurance/building/edit/:publicId",
        name: "admin-edit-building-house-insurance-claims",
        component: () =>
          import("@/views/admin/claims/house-insurance/building/Edit.vue"),
        props: true,
      },
      {
        path:
          "/admin/claims/house-insurance/building/settlement-offer/:publicId",
        name: "admin-building-house-insurance-claims-settlement-offer",
        component: () =>
          import(
            "@/views/admin/claims/house-insurance/building/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path:
          "/admin/claims/claim-information/house-insurance/personal-accident/:publicId",
        name: "admin-personal-accident-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/personal-accident/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/admin/claims/claim-information/house-insurance/contents/:publicId",
        name: "admin-contents-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path: "/admin/dashboard",
        name: "admin-dashboard",
        component: () => import("@/views/admin/Dashboard.vue"),
      },
      {
        path: "/admin/vehicles",
        name: "admin-vehicles",
        component: () => import("@/views/admin/vehicles/Index.vue"),
      },
      {
        path: "/finance/dashboard",
        name: "finance-admin-dashboard",
        component: () => import("@/views/finance/Dashboard.vue"),
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/reusable/Faq.vue"),
      },
      {
        path: "/about-wealth-pro",
        name: "about-wealth-pro",
        component: () => import("@/views/reusable/AboutWealthPro.vue"),
      },
      {
        path: "/policy",
        name: "client-policy",
        component: () => import("@/views/reusable/policies/Policies.vue"),
      },
      {
        path: "/policy/policy-information/motor-insurance/:publicId",
        name: "motor-insurance-policy-information",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/motor-insurance/PolicyInformation.vue"
          ),
        props: true,
      },
      {
        path: "/policy/policy-information/house-insurance/building/:publicId",
        name: "building-house-policy-information",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/BuildingPolicyInformation.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/house-insurance/owned-building/:publicId",
        name: "owned-building-house-policy-information",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/OwnedBuildingPolicyInformation.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/house-insurance/rented-building/:publicId",
        name: "rented-building-house-policy-information",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/RentedBuildingPolicyInformation.vue"
          ),
        props: true,
      },
      {
        path: "/policy/policy-information/house-insurance/contents/:publicId",
        name: "contents-house-policy-information",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/ContentsPolicyInformation.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/house-insurance/personal-accident/:publicId",
        name: "personal-accident-house-policy-information",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/PersonalAccidentPolicyInformation.vue"
          ),
        props: true,
      },
      {
        path: "/policy/addition/motor-insurance",
        name: "client-policy-information-motor-addition",
        component: () =>
          import("@/views/reusable/policies/addition/MotorInsurance.vue"),
      },
      {
        path: "/policy/addition/edit/motor-insurance/:publicId",
        name: "motor-insurance-edit-addition",
        component: () =>
          import("@/views/reusable/policies/addition/MotorInsuranceEdit.vue"),
        props: true,
      },
      {
        path: "/policy/addition/motor-insurance/:publicId",
        name: "client-policy-information-motor-addition-prop",
        component: () =>
          import("@/views/reusable/policies/addition/MotorInsurance.vue"),
        props: true,
      },
      {
        path: "/policy/renewal/motor-insurance",
        name: "motor-insurance-renewal",
        component: () =>
          import("@/views/reusable/policies/renewal/MotorInsurance.vue"),
      },
      {
        path: "/policy/renewal/motor-insurance/:publicId",
        name: "motor-insurance-renewal-prop",
        component: () =>
          import("@/views/reusable/policies/renewal/MotorInsurance.vue"),
        props: true,
      },
      {
        path: "/policy/renewal/edit/motor-insurance/:publicId",
        name: "motor-insurance-edit-renewal",
        component: () =>
          import("@/views/reusable/policies/renewal/MotorInsuranceEdit.vue"),
        props: true,
      },
      {
        path: "/policy/renewal/house-insurance/personal-accident",
        name: "personal-accident-insurance-renewal",
        component: () =>
          import("@/views/reusable/policies/renewal/PersonalAccident.vue"),
      },
      {
        path: "/policy/renewal/house-insurance/personal-accident/:publicId",
        name: "personal-accident-insurance-renewal-prop",
        component: () =>
          import("@/views/reusable/policies/renewal/PersonalAccident.vue"),
        props: true,
      },
      {
        path:
          "/policy/renewal/edit/house-insurance/personal-accident/:publicId",
        name: "personal-accident-insurance-edit-renewal",
        component: () =>
          import("@/views/reusable/policies/renewal/PersonalAccidentEdit.vue"),
        props: true,
      },

      {
        path: "/policy/renewal/house-insurance/owned-building/",
        name: "renewal-owned-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/renewal/OwnedBuildingInsurance.vue"
          ),
      },
      {
        path: "/policy/renewal/house-insurance/owned-building/:publicId",
        name: "renewal-owned-building-house-insurance-prop",
        component: () =>
          import(
            "@/views/reusable/policies/renewal/OwnedBuildingInsurance.vue"
          ),
        props: true,
      },
      {
        path: "/policy/renewal/edit/house-insurance/owned-building/:publicId",
        name: "renewal-edit-owned-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/renewal/OwnedBuildingInsuranceEdit.vue"
          ),
        props: true,
      },
      {
        path: "/policy/renewal/house-insurance/rented-building/:publicId",
        name: "renewal-rented-building-house-insurance-prop",
        component: () =>
          import(
            "@/views/reusable/policies/renewal/RentedBuildingInsurance.vue"
          ),
        props: true,
      },
      {
        path: "/policy/renewal/house-insurance/rented-building",
        name: "renewal-rented-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/renewal/RentedBuildingInsurance.vue"
          ),
      },
      {
        path: "/policy/renewal/edit/house-insurance/rented-building/:publicId",
        name: "renewal-edit-rented-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/renewal/RentedBuildingInsuranceEdit.vue"
          ),
        props: true,
      },
      {
        path: "/policy/addition/house-insurance/owned-building/",
        name: "addition-owned-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/addition/OwnedBuildingInsurance.vue"
          ),
      },
      {
        path: "/policy/addition/house-insurance/owned-building/:publicId",
        name: "addition-owned-building-house-insurance-prop",
        component: () =>
          import(
            "@/views/reusable/policies/addition/OwnedBuildingInsurance.vue"
          ),
        props: true,
      },
      {
        path: "/policy/addition/edit/house-insurance/owned-building/:publicId",
        name: "addition-edit-owned-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/addition/OwnedBuildingInsuranceEdit.vue"
          ),
        props: true,
      },
      {
        path: "/policy/addition/house-insurance/rented-building/:publicId",
        name: "addition-rented-building-house-insurance-prop",
        component: () =>
          import(
            "@/views/reusable/policies/addition/RentedBuildingInsurance.vue"
          ),
        props: true,
      },
      {
        path: "/policy/addition/house-insurance/rented-building",
        name: "addition-rented-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/addition/RentedBuildingInsurance.vue"
          ),
      },
      {
        path: "/policy/addition/edit/house-insurance/rented-building/:publicId",
        name: "addition-edit-rented-building-house-insurance",
        component: () =>
          import(
            "@/views/reusable/policies/addition/RentedBuildingInsuranceEdit.vue"
          ),
        props: true,
      },
      {
        path: "/policy/addition/house-insurance/personal-accident",
        name: "client-policy-information-personal-accident-addition",
        component: () =>
          import("@/views/reusable/policies/addition/PersonalAccident.vue"),
      },
      {
        path: "/policy/addition/house-insurance/personal-accident/:publicId",
        name: "client-policy-information-personal-accident-addition-prop",
        component: () =>
          import("@/views/reusable/policies/addition/PersonalAccident.vue"),
        props: true,
      },
      {
        path:
          "/policy/addition/edit/house-insurance/personal-accident/:publicId",
        name: "edit-personal-accident-addition",
        component: () =>
          import("@/views/reusable/policies/addition/PersonalAccidentEdit.vue"),
        props: true,
      },
      {
        path: "/policy/addition/house-insurance/contents",
        name: "client-policy-information-contents-addition",
        component: () =>
          import("@/views/reusable/policies/addition/Contents.vue"),
      },
      {
        path: "/policy/addition/house-insurance/contents/:publicId",
        name: "client-policy-information-contents-addition-prop",
        component: () =>
          import("@/views/reusable/policies/addition/Contents.vue"),
        props: true,
      },
      {
        path: "/policy/addition/edit/house-insurance/contents/:publicId",
        name: "edit-contents-addition",
        component: () =>
          import("@/views/reusable/policies/addition/ContentsEdit.vue"),
        props: true,
      },
      {
        path: "/policy/renewal/house-insurance/contents",
        name: "contents-renewal",
        component: () =>
          import("@/views/reusable/policies/renewal/Contents.vue"),
      },
      {
        path: "/policy/renewal/house-insurance/contents/:publicId",
        name: "contents-renewal-prop",
        component: () =>
          import("@/views/reusable/policies/renewal/Contents.vue"),
        props: true,
      },
      {
        path: "/policy/renewal/edit/house-insurance/contents/:publicId",
        name: "edit-contents-renewal",
        component: () =>
          import("@/views/reusable/policies/renewal/ContentsEdit.vue"),
        props: true,
      },
      {
        path: "/policy/claim/motor-insurance",
        name: "report-motor-insurance-claim",
        component: () =>
          import("@/views/reusable/claims/motor-insurance/Index.vue"),
        // props: true,
      },
      {
        path: "/policy/claim/house-insurance/building",
        name: "client-policy-building-house-insurance-claim",
        component: () =>
          import("@/views/reusable/claims/house-insurance/building/Index.vue"),
        // props: true,
      },
      {
        path: "/policy/claim/house-insurance/contents/:publicId",
        name: "client-policy-contents-house-insurance-claim",
        component: () =>
          import("@/views/reusable/claims/house-insurance/contents/Index.vue"),
        props: true,
      },
      {
        path: "/policy/claim/house-insurance/personal-accident",
        name: "client-policy-personal-accident-house-insurance-claim",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/personal-accident/Index.vue"
          ),
      },
      {
        path: "/policy/claim/house-insurance/contents",
        name: "client-policy-contents-house-insurance-claim",
        component: () =>
          import("@/views/reusable/claims/house-insurance/contents/Index.vue"),
      },
      {
        path: "/policy/policy-information/credit-note/:publicId",
        name: "client-policy-credit-note",
        component: () =>
          import("@/views/reusable/policies/policy-information/CreditNote.vue"),
        props: true,
      },
      {
        path: "/policy/policy-information/certificate/motor-insurance",
        name: "client-policy-motor-certificate",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/certificates/MotorCertificate.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/certificate/motor-insurance/confirmation-of-cover/:publicId",
        name: "policy-motor-confirmation-of-cover",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/certificates/MotorConfirmationOfCover.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/certificate/house-insurance/confirmation-of-cover/:publicId",
        name: "policy-house-confirmation-of-cover",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/certificates/HouseConfirmationOfCover.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/certificate/house-insurance/personal-accident/confirmation-of-cover/:publicId",
        name: "policy-personal-accident-confirmation-of-cover",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/certificates/PersonalAccidentConfirmationOfCover.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/certificate/house-insurance/contents/confirmation-of-cover/:publicId",
        name: "policy-contents-confirmation-of-cover",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/certificates/ContentsConfirmationOfCover.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/motor-insurance/motor-schedule-details/:publicId",
        name: "motor-insurance-policy-details",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/motor-insurance/PolicyDetails.vue"
          ),
        props: true,
      },
      {
        path: "/policy/policy-information/receipt/:publicId",
        name: "policy-receipt-details",
        component: () =>
          import("@/views/reusable/policies/policy-information/Receipt.vue"),
        props: true,
      },
      {
        path: "/policy/policy-information/invoice/:publicId",
        name: "policy-debit-note-details",
        component: () =>
          import("@/views/reusable/policies/policy-information/Invoice.vue"),
        props: true,
      },
      {
        path: "/policy/policy-information/credit-note/:publicId",
        name: "policy-credit-note-details",
        component: () =>
          import("@/views/reusable/policies/policy-information/CreditNote.vue"),
        props: true,
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/reusable/Notification.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/individual/profile/Overview.vue"),
      },
      {
        path: "/profile/beneficiary-information",
        name: "beneficiary-information",
        component: () =>
          import("@/views/individual/profile/BeneficiaryInformation.vue"),
      },
      {
        path: "/profile/beneficiary-information/edit/:publicId",
        name: "edit-beneficiary-information",
        component: () =>
          import("@/views/individual/profile/EditBeneficiaryInformation.vue"),
        props: true,
      },
      {
        path: "/profile/employer-information",
        name: "employer-information",
        component: () =>
          import("@/views/individual/profile/EmployerInformation.vue"),
      },
      {
        path: "/profile/hospital-information",
        name: "hospital-information",
        component: () =>
          import("@/views/individual/profile/HospitalInformation.vue"),
      },
      {
        path: "/profile/personal-information",
        name: "personal-information",
        component: () =>
          import("@/views/individual/profile/PersonalInformation.vue"),
        props: true,
      },
      {
        path: "/profile/relationship-information",
        name: "relationship-information",
        component: () =>
          import("@/views/individual/profile/RelationshipInformation.vue"),
      },
      {
        path: "/profile/relationship-information/edit/:publicId",
        name: "edit-relationship-information",
        component: () =>
          import("@/views/individual/profile/EditRelationshipInformation.vue"),
        props: true,
      },
      {
        path: "/profile/change-password",
        name: "change-password",
        component: () => import("@/views/reusable/ChangePassword.vue"),
      },
      {
        path: "/add-underwriters",
        name: "add-underwriters",
        component: () =>
          import("@/views/reusable/underwriters/AddUnderwriters.vue"),
      },
      {
        path: "/edit-underwriters",
        name: "edit-underwriters",
        component: () =>
          import("@/views/reusable/underwriters/EditUnderwriters.vue"),
      },
      {
        path: "/insurance-packages",
        name: "insurance-packages",
        component: () =>
          import("@/views/reusable/buy-insurance/InsurancePackages.vue"),
      },
      {
        path: "/insurance-packages/cart",
        name: "insurance-packages-cart",
        component: () => import("@/views/reusable/buy-insurance/cart/Cart.vue"),
      },
      {
        path: "/insurance-packages/cart/debit-note/:publicId",
        name: "insurance-packages-cart-debit-note",
        component: () =>
          import("@/views/reusable/buy-insurance/cart/CartDebitNote.vue"),
        props: true,
      },
      {
        path: "/insurance-packages/motor-insurance",
        name: "motor-insurance-package",
        component: () =>
          import("@/views/reusable/buy-insurance/motor-insurance/Index.vue"),
      },
      {
        path: "/insurance-packages/motor-insurance/quote",
        name: "motor-insurance-package-quote",
        component: () =>
          import("@/views/reusable/buy-insurance/motor-insurance/Quote.vue"),
      },
      {
        path: "/insurance-packages/motor-insurance/edit-quote/:publicId",
        name: "motor-insurance-package-edit-quote",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/motor-insurance/EditInvoice.vue"
          ),
        props: true,
      },
      {
        path:
          "/insurance-packages/house-insurance/edit-owned-building-quote/:publicId",
        name: "edit-owned-building-quote",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/edit/building/OwnBuilding.vue"
          ),
        props: true,
      },
      {
        path:
          "/insurance-packages/house-insurance/edit-rented-building-quote/:publicId",
        name: "edit-rented-building-quote",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/edit/building/RentBuilding.vue"
          ),
        props: true,
      },
      {
        path:
          "/insurance-packages/home-insurance/edit-personal-accident/:publicId",
        name: "edit-personal-accident-quote",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/edit/PersonalAccident.vue"
          ),
        props: true,
      },
      {
        path: "/insurance-packages/home-insurance/edit-contents/:publicId",
        name: "edit-contents-quote",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/edit/Contents.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/house-insurance/rented-building-details/:publicId",
        name: "rented-building-house-insurance-policy-details",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/RentedBuildingDetails.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/house-insurance/contents-details/:publicId",
        name: "contents-house-insurance-policy-details",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/ContentsDetails.vue"
          ),
        props: true,
      },
      {
        path:
          "/policy/policy-information/house-insurance/owned-building-details/:publicId",
        name: "owned-building-house-insurance-policy-details",
        component: () =>
          import(
            "@/views/reusable/policies/policy-information/house-insurance/OwnedBuildingDetails.vue"
          ),
        props: true,
      },
      {
        path: "/insurance-packages/buy-insurance/policy-period/:publicId",
        name: "insurance-packages-policy-period",
        component: () =>
          import("@/views/reusable/buy-insurance/PolicyPeriod.vue"),
        props: true,
      },
      {
        path: "/insurance-packages/motor-insurance/existing-policy-quote",
        name: "motor-insurance-package-existing-policy-quote",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/motor-insurance/ExistingPolicyQuote.vue"
          ),
      },
      {
        path: "/insurance-packages/house-insurance",
        name: "house-insurance-package",
        component: () =>
          import("@/views/reusable/buy-insurance/house-insurance/Index.vue"),
      },
      {
        path: "/insurance-packages/home-insurance/building/:publicId",
        name: "building-house-insurance-package",
        component: () =>
          import("@/views/reusable/buy-insurance/house-insurance/Building.vue"),
        props: true,
      },
      {
        path: "/insurance-packages/home-insurance/home-owner/:publicId",
        name: "home-owner-house-insurance-package",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/HomeOwner.vue"
          ),
        props: true,
      },
      {
        path: "/insurance-packages/home-insurance/house-holder/:publicId",
        name: "house-holder-house-insurance-package",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/HouseHolder.vue"
          ),
        props: true,
      },
      {
        path: "/insurance-packages/home-insurance/contents/:publicId",
        name: "contents-house-insurance-package",
        component: () =>
          import("@/views/reusable/buy-insurance/house-insurance/Contents.vue"),
        props: true,
      },
      {
        path: "/insurance-packages/home-insurance/personal-accident/:publicId",
        name: "personal-accident-house-insurance-package",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/PersonalAccident.vue"
          ),
        props: true,
      },
      {
        path:
          "/insurance-packages/home-insurance/third-party-liability/:publicId",
        name: "third-party-liability-house-insurance-package",
        component: () =>
          import(
            "@/views/reusable/buy-insurance/house-insurance/PersonalLiability.vue"
          ),
        props: true,
      },
      {
        path: "/cooperative/dashboard",
        name: "cooperative-admin-dashboard",
        component: () => import("@/views/cooperative-admin/Overview.vue"),
      },
      {
        path: "/cooperative/members",
        name: "cooperative-admin-members-list",
        component: () =>
          import("@/views/cooperative-admin/members/MembersList.vue"),
      },
      {
        path: "/cooperative/members/create",
        name: "cooperative-admin-members-add-new",
        component: () =>
          import("@/views/cooperative-admin/members/AddNewMembers.vue"),
      },
      {
        path: "/cooperative/members/edit/:publicId",
        name: "cooperative-admin-edit-member",
        component: () =>
          import("@/views/cooperative-admin/members/EditMember.vue"),
        props: true,
      },
      {
        path: "/cooperative/members/:publicId",
        name: "cooperative-admin-members-overview",
        component: () =>
          import("@/views/cooperative-admin/members/MemberOverview.vue"),
        props: true,
      },
      {
        path: "/cooperative/policies",
        name: "cooperative-admin-policy-listing",
        component: () => import("@/views/cooperative-admin/policies/Index.vue"),
      },
      {
        path: "/cooperative/policies/policy-information/:publicId",
        name: "cooperative-admin-members-policy-details",
        component: () =>
          import("@/views/cooperative-admin/policies/MemberPolicies.vue"),
        props: true,
      },
      {
        path:
          "/cooperative/policies/policy-information/motor-insurance/:publicId",
        name: "cooperative-admin-members-motor-insurance-policy-information",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/motor-insurance/Index.vue"
          ),
      },
      {
        path:
          "/cooperative/policies/policy-information/motor-insurance/vehicle/details/:publicId",
        name: "cooperative-admin-members-motor-vehicle-details",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/motor-insurance/VehicleSchedule.vue"
          ),
      },
      {
        path:
          "/cooperative/policies/policy-information/house-insurance/:publicId",
        name: "cooperative-admin-members-house-insurance-policy-information",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/house-insurance/Index.vue"
          ),
      },
      {
        path:
          "/cooperative/policies/policy-information/house-insurance/building/owned/:publicId",
        name:
          "cooperative-admin-members-house-insurance-owned-building-policy-information",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/house-insurance/OwnedBuildingPolicyInformation.vue"
          ),
      },
      {
        path:
          "/cooperative/policies/policy-information/house-insurance/building/owned/details/:publicId",
        name: "cooperative-admin-members-owned-building-details",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/house-insurance/OwnedBuildingDetails.vue"
          ),
      },
      {
        path:
          "/cooperative/policies/policy-information/house-insurance/building/rented/:publicId",
        name:
          "cooperative-admin-members-house-insurance-rented-building-policy-information",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/house-insurance/RentedBuildingPolicyInformation.vue"
          ),
      },
      {
        path:
          "/cooperative/policies/policy-information/house-insurance/building/rented/details/:publicId",
        name: "cooperative-admin-members-rented-building-details",
        component: () =>
          import(
            "@/views/cooperative-admin/policies/policy-information/house-insurance/RentedBuildingDetails.vue"
          ),
      },
      {
        path: "/cooperative/cart",
        name: "cooperative-admin-insurance-packages-cart",
        component: () => import("@/views/cooperative-admin/cart/Cart.vue"),
      },
      {
        path: "/cooperative/motor-insurance/edit/:publicId",
        name: "edit-cooperative-motor-insurance-cart",
        component: () =>
          import(
            "@/views/cooperative-admin/insurance-packages/motor-insurance/Edit.vue"
          ),
        props: true,
      },
      {
        path: "/cooperative/members/claims",
        name: "cooperative-admin-members-claims-listing",
        component: () =>
          import("@/views/cooperative-admin/members/claims/Index.vue"),
      },
      {
        path: "/cooperative/members/claims/report-claims",
        name: "cooperative-admin-report-members-claims",
        component: () =>
          import("@/views/cooperative-admin/members/claims/ReportClaims.vue"),
      },
      {
        path: "/cooperative/members/claims/report-claims/motor-insurance",
        name: "cooperative-admin-report-members-motor-insurance-claims",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/motor-insurance/Index.vue"
          ),
      },
      {
        path: "/cooperative/members/claims/motor-insurance/:publicId",
        name: "cooperative-admin-motor-insurance-claims-details",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/motor-insurance/Details.vue"
          ),
        props: true,
      },
      {
        path: "/cooperative/members/claims/motor-insurance/edit/:publicId",
        name: "cooperative-admin-edit-motor-insurance-claims-details",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/motor-insurance/Edit.vue"
          ),
        props: true,
      },
      {
        path:
          "/cooperative/members/claims/motor-insurance/settlement-offer/:publicId",
        name: "cooperative-admin-motor-insurance-settlement-offer",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/motor-insurance/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path:
          "/cooperative/members/claims/motor-insurance/discharge-voucher/:publicId",
        name: "cooperative-admin-motor-insurance-discharge-voucher",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/motor-insurance/DischargeVoucher.vue"
          ),
        props: true,
      },
      {
        path:
          "/cooperative/members/claims/report-claims/house-insurance/building",
        name:
          "cooperative-admin-report-members-building-house-insurance-claims",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/house-insurance/building/Index.vue"
          ),
      },
      {
        path:
          "/cooperative/members/claims/house-insurance/building/details/:publicId",
        name: "cooperative-admin-building-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path: "/cooperative/members/claims/house-insurance/edit/:publicId",
        name: "cooperative-admin-edit-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/cooperative-admin/members/claims/house-insurance/building/Edit.vue"
          ),
        props: true,
      },
      {
        path: "/cooperative/insurance-packages/house-insurance",
        name: "cooperative-admin-house-insurance-package",
        component: () =>
          import(
            "@/views/cooperative-admin/insurance-packages/house-insurance/Index.vue"
          ),
      },
      {
        path: "/cooperative/insurance-packages/motor-insurance",
        name: "cooperative-admin-motor-insurance-package",
        component: () =>
          import(
            "@/views/cooperative-admin/insurance-packages/motor-insurance/Index.vue"
          ),
      },
      {
        path:
          "/cooperative/insurance-packages/motor-insurance/quote/:publicId/:businessType:/:businessClass/:coopPublicId",
        name: "cooperative-admin-buy-private-motor-insurance-package",
        component: () =>
          import(
            "@/views/cooperative-admin/insurance-packages/motor-insurance/Quote.vue"
          ),
        // props: true,
      },
      {
        path:
          "/cooperative/insurance-packages/house-insurance/quote/:publicId/:coopPublicId/:package",
        name: "cooperative-admin-buy-house-building-insurance-package",
        component: () =>
          import(
            "@/views/cooperative-admin/insurance-packages/house-insurance/building/Quote.vue"
          ),
        props: true,
      },
      {
        path: "/broker/clients",
        name: "broker-client-listing",
        component: () => import("@/views/broker/clients/Index.vue"),
      },
      {
        path: "/broker/clients/details/:publicId",
        name: "broker-client-details",
        component: () => import("@/views/broker/clients/Information.vue"),
        props: true,
      },
      {
        path: "/broker/clients/policies",
        name: "broker-client-policy-listing",
        component: () => import("@/views/broker/clients/policies/Index.vue"),
      },
      {
        path:
          "/broker/clients/policy/policy-information/motor-insurance/:publicId",
        name: "broker-client-motor-insurance-policy-information",
        component: () =>
          import("@/views/broker/clients/policies/MotorInsurance.vue"),
        props: true,
      },
      {
        path:
          "/broker/clients/policy/policy-information/house-insurance/owned-building/:publicId",
        name: "broker-client-owned-building-policy-information",
        component: () =>
          import("@/views/broker/clients/policies/OwnedBuilding.vue"),
        props: true,
      },
      {
        path:
          "/broker/clients/policy/policy-information/house-insurance/rented-building/:publicId",
        name: "broker-client-rented-building-policy-information",
        component: () =>
          import("@/views/broker/clients/policies/RentedBuilding.vue"),
        props: true,
      },
      {
        path: "/broker/clients/folio-item/:publicId",
        name: "broker-client-policy-motor-schedule",
        component: () =>
          import("@/views/broker/clients/policies/MotorInsurance.vue"),
        props: true,
      },
      {
        path: "/broker/clients/invoices",
        name: "broker-client-invoices-listing",
        component: () => import("@/views/broker/clients/invoices/Index.vue"),
      },
      {
        path: "/broker/clients/invoices/details/:publicId",
        name: "broker-client-invoice-details",
        component: () => import("@/views/broker/clients/invoices/Details.vue"),
        props: true,
      },
      {
        path: "/broker/clients/claims",
        name: "broker-client-claims-listing",
        component: () => import("@/views/broker/clients/claims/Index.vue"),
      },
      {
        path: "/broker/clients/claims/settlement-offers",
        name: "broker-client-claims-settlement-offers-listing",
        component: () =>
          import("@/views/broker/clients/claims/SettlementOffers.vue"),
      },
      {
        path: "/broker/claims/claim-information/motor-insurance/:publicId",
        name: "broker-client-motor-insurance-claims-details",
        component: () =>
          import("@/views/broker/clients/claims/motor-insurance/Details.vue"),
        props: true,
      },
      {
        path: "/broker/claims/motor-insurance/edit/:publicId",
        name: "broker-motor-insurance-claims-edit",
        component: () =>
          import("@/views/broker/clients/claims/motor-insurance/Edit.vue"),
        props: true,
      },
      {
        path: "/broker/claims/motor-insurance/settlement-offer/:publicId",
        name: "broker-motor-insurance-claims-settlement-offer",
        component: () =>
          import(
            "@/views/broker/clients/claims/motor-insurance/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path: "/broker/claims/motor-insurance/discharge-voucher/:publicId",
        name: "broker-motor-insurance-claims-discharge-voucher",
        component: () =>
          import(
            "@/views/broker/clients/claims/motor-insurance/DischargeVoucher.vue"
          ),
        props: true,
      },
      {
        path:
          "/broker/claims/house-insurance/building/discharge-voucher/:publicId",
        name: "broker-building-house-insurance-claims-discharge-voucher",
        component: () =>
          import(
            "@/views/broker/clients/claims/house-insurance/building/DischargeVoucher.vue"
          ),
        props: true,
      },
      {
        path:
          "/broker/claims/claim-information/house-insurance/building/:publicId",
        name: "broker-building-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/broker/clients/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path: "/broker/claims/house-insurance/building/edit/:publicId",
        name: "broker-edit-building-house-insurance-claims",
        component: () =>
          import(
            "@/views/broker/clients/claims/house-insurance/building/Edit.vue"
          ),
        props: true,
      },
      {
        path:
          "/broker/claims/house-insurance/building/settlement-offer/:publicId",
        name: "broker-building-house-insurance-claims-settlement-offer",
        component: () =>
          import(
            "@/views/broker/clients/claims/house-insurance/building/SettlementOffer.vue"
          ),
        props: true,
      },
      {
        path:
          "/broker/claims/claim-information/house-insurance/personal-accident/:publicId",
        name: "broker-personal-accident-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/personal-accident/Details.vue"
          ),
        props: true,
      },
      {
        path:
          "/broker/claims/claim-information/house-insurance/contents/:publicId",
        name: "broker-contents-house-insurance-claims-details",
        component: () =>
          import(
            "@/views/reusable/claims/house-insurance/building/Details.vue"
          ),
        props: true,
      },
      {
        path: "/broker/policies/upload-document",
        name: "broker-upload-policy-document",
        component: () =>
          import("@/views/broker/clients/policies/UploadDocument.vue"),
      },
      {
        path: "/broker/policies/upload-certificate",
        name: "broker-upload-policy-certificate",
        component: () =>
          import("@/views/broker/clients/policies/UploadCertificate.vue"),
      },
      {
        path: "/broker/policies/approve-certificate",
        name: "broker-approve-policy-certificate",
        component: () =>
          import("@/views/broker/clients/policies/ApproveCertificate.vue"),
      },
      {
        path: "/broker/policies/approve-document",
        name: "broker-approve-policy-document",
        component: () =>
          import("@/views/broker/clients/policies/ApproveDocument.vue"),
      },
      {
        path: "/broker/cooperative-societies/umbrella-policy",
        name: "broker-umbrella-policy",
        component: () =>
          import("@/views/broker/clients/policies/umbrella-policy/Index.vue"),
      },
      {
        path: "/broker/cooperative-societies/umbrella-policy/create",
        name: "create-broker-umbrella-policy",
        component: () =>
          import(
            "@/views/broker/clients/policies/umbrella-policy/CreateUmbrellaPolicy.vue"
          ),
      },
      {
        path: "/broker/cooperative-societies/umbrella-policy/edit/:publicId",
        name: "edit-broker-umbrella-policy",
        component: () =>
          import(
            "@/views/broker/clients/policies/umbrella-policy/EditUmbrellaPolicy.vue"
          ),
        props: true,
      },
      {
        path: "/broker/cooperative-societies/create",
        name: "broker-create-cooperative-societies",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Create.vue"),
      },
      {
        path: "/broker/cooperative-societies/edit/:publicId",
        name: "broker-edit-cooperative-societies",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Edit.vue"),
        props: true,
      },
      {
        path: "/broker/cooperative-societies/:publicId",
        name: "broker-cooperative-societies-details",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Details.vue"),
        props: true,
      },
      {
        path: "/broker/cooperative-societies",
        name: "broker-cooperative-societies",
        component: () =>
          import("@/views/admin/users/cooperatives-societies/Index.vue"),
      },
      {
        path: "/broker/cooperative-societies/claims",
        name: "broker-cooperative-societies-claims",
        component: () =>
          import("@/views/broker/cooperatives-societies/claims/Index.vue"),
      },
      {
        path: "/broker/policy-migration",
        name: "broker-policy-migration-list",
        component: () => import("@/views/broker/policy-migration/Index.vue"),
      },
      {
        path: "/broker/policy-migration/rate",
        name: "broker-policy-migration-rate",
        component: () => import("@/views/broker/policy-migration/Rate.vue"),
      },
      {
        path: "/broker/policy-migration/create",
        name: "broker-policy-migration-creation",
        component: () =>
          import("@/views/broker/policy-migration/Migration.vue"),
      },
    ],
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/home",
    name: "landing-page",
    component: () => import("@/views/frontend/LandingPage.vue"),
  },
  {
    path: "/frequently-asked-questions",
    name: "frequently-asked-questions",
    component: () => import("@/views/frontend/FrequentlyAskedQuestions.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("@/views/frontend/PrivacyPolicy.vue"),
  },
  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: () => import("@/views/frontend/TermsOfUse.vue"),
  },
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/auth/SignIn.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/underwriter/sign-in",
    name: "underwriter-sign-in",
    component: () => import("@/views/auth/UnderwriterSignIn.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue"),
  },
  {
    path: "/sign-up/verify-email",
    name: "verify-email",
    component: () => import("@/views/auth/VerifyEmail.vue"),
  },
  {
    path: "/verification/:hash",
    name: "get-email-verification-code",
    component: () => import("@/views/auth/GetEmailVerificationCode.vue"),
    props: true,
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH);

  ifNotAdministrator;

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
