import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";

const redirectToSignIn = () => {
  if (JwtService.getToken()) {
    JwtService.destroyToken();
    return (window.location.href = "/sign-in");
  }
};

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    // console.log("Base URIL: ", process.env.VUE_APP_BASE_URL);
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL =
      process.env.VUE_APP_BASE_URL;

    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        // If response status is 401 Unauthorized, redirect to login
        if (error.response && error.response.status === 401) {
          redirectToSignIn();
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setResponseType(type): void {
    // ApiService.vueInstance.axios.defaults.headers.common["responseType"] = type;
    ApiService.vueInstance.axios.defaults.responseType = type;
  }

  /**
   * @description set the default HTTP request headers
   */
  public static create(): void {
    ApiService.vueInstance.axios.defaults.headers.common["headers"] = [
      "Content-Type",
      "multipart/form-data",
    ];
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse["data"]> {
    let url = resource;
    if (slug !== "") {
      url += `/${slug}`;
    }
    return ApiService.vueInstance.axios.get(url);
    // .catch((error) => {
    //   throw new Error(`[KT] ApiService ${error}`);
    // });
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params?: AxiosRequestConfig["data"],
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse["data"]> {
    return ApiService.vueInstance.axios.post(`${resource}`, params, config);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the PATCH HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static patch(
    resource: string,
    params: AxiosRequestConfig["data"],
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse["data"]> {
    return ApiService.vueInstance.axios.patch(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse["data"]> {
    return ApiService.vueInstance.axios.delete(resource);

    // return ApiService.vueInstance.axios.delete(resource).catch((error) => {
    //   throw new Error(`[RWV] ApiService ${error}`);
    // });
  }
}

export default ApiService;
