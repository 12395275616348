import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import variables from "@/router/api";
export interface User {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  token: string;
}
export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  cartCount = 0;
  cartTotalAmount = 0;
  notificationsCount = 0;
  avatar = "";

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  /**
   * Get current user cart count
   * @returns cartCount
   */
  get getCartCount(): number {
    return this.cartCount;
  }

  /**
   * Get current user cart count
   * @returns cartTotalAmount
   */
  get getcartTotalAmount(): number {
    return this.cartTotalAmount;
  }

  /**
   * Get current user total unread notifications count
   * @returns notificationsCount
   */
  get getNotificationCount(): number {
    return this.notificationsCount;
  }

  /**
   * Get current user avatar
   * @returns avatar
   */
  get getAvatar(): string {
    return this.avatar;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](token) {
    this.isAuthenticated = true;
    // this.user = token;
    this.errors = [];
    JwtService.saveToken(token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_ROLE](role) {
    this.user.role = role;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Mutation
  [Mutations.CART_MUTATION](cart) {
    this.cartCount = cart.count;
    this.cartTotalAmount = cart.totalAmount;
  }

  @Mutation
  [Mutations.NOTIFICATION_MUTATION](notifications) {
    this.notificationsCount = notifications;
  }

  @Mutation
  [Mutations.SET_AVATAR](avatar) {
    this.avatar =
      avatar == "" || avatar == undefined
        ? "/media/avatars/blank.png"
        : `${process.env.VUE_APP_BASE_URL}/avatar/${avatar}`;
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(variables.LOGIN_ROUTE, credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data.token);
          this.context.commit(Mutations.SET_USER, data.user);
          this.context.commit(Mutations.SET_AVATAR, data.user.avatar);
          this.context.commit(Mutations.CART_MUTATION, data.user.cart);
          this.context.commit(
            Mutations.NOTIFICATION_MUTATION,
            data.user.notifications
          );

          //Save authenticated User Role, Avatar, and motor packages ID's to localStorage
          JwtService.saveUserRole(data.user.role);
          JwtService.saveUserAvatar(data.user.avatar);
          // JwtService.thirdPartyPM(data.user.thirdPartyIds.PM);
          // JwtService.thirdPartyCM(data.user.thirdPartyIds.CM);

          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post(variables.REGISTER_ROUTE, credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.error);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(variables.VERIFY_USER)
        .then(({ data }) => {
          // console.log(data);
          this.context.commit(Mutations.SET_AUTH, data.token);
          this.context.commit(Mutations.SET_USER, data.user);
          this.context.commit(Mutations.SET_AVATAR, data.user.avatar);
          this.context.commit(Mutations.CART_MUTATION, data.user.cart);
          this.context.commit(
            Mutations.NOTIFICATION_MUTATION,
            data.user.notifications
          );

          JwtService.saveUserAvatar(data.user.avatar);
          // JwtService.thirdPartyPM(data.user.thirdPartyIds.PM);
          // JwtService.thirdPartyCM(data.user.thirdPartyIds.CM);
        })
        .catch((data) => {
          this.context.commit(Mutations.PURGE_AUTH);
          // this.context.commit(Mutations.SET_ERROR, data.response.data.error);
        });
      // .catch(({ data }) => {
      //   // this.context.commit(Mutations.SET_ERROR, response.data.error);
      // });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
